.zen-tabs {
  &__header-row {
    display: flex;
  }

  &__title {
    display: inline-block;
    margin-right: 30px;
  }

  &__right-content {
    margin-left: auto;
    margin-top: auto;
  }
}

.zen-tab-header {
  &__outer-container {
    display: inline-block;
  }

  &__heading {
    display: inline-block;
    margin: 0;
    padding: 5px 0;

    &:hover {
      border-bottom: 3px solid $blue-primary-hover;
      color: $slate-hover;
    }

    &--active {
      border-bottom: 3px solid $blue-primary;

      &:hover {
        border-bottom: 3px solid $blue-primary-active;
        color: $slate;
      }
    }

    &--use-light-weight-heading {
      font-weight: 400;
      text-transform: none;
    }

    &--disabled {
      color: $gray;

      &:hover {
        border-bottom: none;
        color: $gray-hover;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
  }
}

.zen-tab-content-wrapper {
  padding-bottom: 30px;
  padding-top: 30px;

  &--no-padding {
    padding: 0;
  }

  &--active {
    display: block;
  }

  &--hidden {
    display: none;
    opacity: 0;
  }
}
