.expando-tree {
  height: 100%;
  width: 100%;

  .expan.node {
    cursor: pointer;
  }

  .node {
    cursor: pointer;
  }

  &__chart {
    height: 100%;
    width: 100%;
  }
}
