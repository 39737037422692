.gd-dashboard-placeholder-tile {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  &__icon {
    color: $blue-primary;
  }

  &__icon-circle-wrapper {
    align-items: center;
    background-color: $blue-lightest;
    display: flex;
    justify-content: center;
  }
}
