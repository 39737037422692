.gd-dashboard-divider_tile {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  &__divider-dot {
    background-color: #42526e;
    border-radius: 50%;
  }
}
