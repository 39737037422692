.visualization-picker-disabled-view {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;

  &__disabled-requirements {
    border: 1px solid $gray;
    font-size: $font-l;
    padding: 30px 22px;
    position: relative;
  }
}
