.copy-dimension-modal_button {
  color: $blue-primary;
}

.dim-matched-row {
  align-items: center;
  display: flex;

  &__value {
    flex: 1;
  }

  &__mapping-link {
    align-items: baseline;
    display: flex;
    gap: 5px;
  }
}
