.gd-dashboard-users-table {
  position: relative;
  width: 100%;

  &__dropdown {
    width: 100%;

    .zen-dropdown {
      float: left;
    }
  }
}
