.ui-draggable-item {
  &.react-draggable {
    z-index: 1;

    &.react-draggable-dragging {
      cursor: grabbing;
      z-index: 2;

      > * {
        @include zen-elevation-4;

        transform: scale(1.01);
      }
    }
  }

  &--fully-draggable {
    cursor: grab;
    touch-action: none;
    user-select: none;
  }
}

.ui-drag-handle {
  cursor: grab;
  display: flex;
  margin-right: 3px;

  &--disabled {
    cursor: initial;
  }
}

// The react-draggable library adds a class to the top of the document that we
// can use to ensure the grabbing cursor is displayed even if the user's mouse
// moves outside the element being dragged. An example is if the user reaches
// the top of a bounding container and cannot drag the element any farther. The
// mouse will continue to but the element will not.
// NOTE(stephen): Need to use the wildcard `*` to ensure this style is applied
// at the highest priority.
body.react-draggable-transparent-selection * {
  cursor: grabbing !important;
}
