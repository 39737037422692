.ds-hdd-icon {
  padding-right: 5px;
}

.data-status-body {
  padding: 25px;
}

.panel-header {
  display: inline-block;
  margin: 0;
}
