.u-heading-large,
.u-heading-medium,
.u-heading-small {
  color: $slate;
  font-family: $base-font-family;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.u-heading--underlined::after {
  border-bottom: 3px solid $slate;
  content: "";
  display: block;
  margin-top: $space-xs-px;
  width: 30px;
}

.u-heading--white {
  color: $white;
}

.u-heading-large {
  font-size: 22px;
  letter-spacing: 0.52px;
}

.u-heading-medium {
  font-size: 18px;
  letter-spacing: 0.6px;
}

.u-heading-small {
  font-size: 15px;
  letter-spacing: 0.6px;
}

p,
.u-paragraph-text {
  color: $slate;
  font-family: $base-font-family;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0.5em;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.u-highlighted-text {
  color: $blue-primary;
}

.u-info-text {
  color: $slate;
  font-family: $base-font-family;
  font-size: 14px;
  font-weight: 400;
}

.u-label-text,
.u-placeholder-text {
  color: $gray;
  font-family: $base-font-family;
  font-size: 14px;
  font-weight: 400;
}

.u-caption-text {
  color: $slate-hover;
  font-family: $base-font-family;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 16px;
}
