.user-select {
  margin-top: $spacing-l;
  position: relative;
  width: 100%;
}

.user-select-dropdown {
  width: 100%;

  .zen-dropdown {
    float: left;
    margin-left: 30px;
    margin-top: 30px;
  }
}
