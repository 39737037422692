.sunburst-info-container {
  height: 120px;

  .percentage,
  .identifier,
  .absolute {
    font-size: 1.5em;
  }

  .sequence text,
  .legend text {
    fill: #fff;
    font-size: 16px;
    font-weight: normal;
  }
}

.sunburst {
  height: 100%;
  text-align: center;
  width: 100%;
}

.sunburst path {
  stroke: #fff;
}
