$button-height: 34px;

.hierarchical-selector-dropdown {
  &__button {
    align-items: center;
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: $button-height;
    justify-content: center;
    line-height: $button-height;
    overflow: hidden;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &:hover {
      background-color: $hierarchical-selector-item-hover;
    }
  }

  &__button-arrow {
    line-height: $button-height;
    position: absolute;
    right: 5px;
  }
}
