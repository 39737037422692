.gd-dashboard-controls {
  align-items: center;
  display: flex;
  justify-content: center;

  &__modification-buttons {
    display: flex;
  }
}

.gd-dashboard-controls-dropdown-button {
  background-color: initial;
  border: none;
  border-radius: 0;
  height: 100%;
  justify-content: center;
  padding: 0;

  &:hover {
    background-color: initial;
    border: none;
  }
}
