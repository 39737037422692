.aqt-customizable-indicator-tag {
  background-color: $aqt-indicator-item-color;
  background-image: $aqt-indicator-item-gradient;
  color: $aqt-font-color-dark;
}

.aqt-customizable-grouping-tag {
  background-color: $aqt-grouping-item-color;
  background-image: $aqt-grouping-item-gradient;
  color: $aqt-font-color-dark;
}

.aqt-customizable-filter-tag:not(.customizable-filter-tag--date-unapplied) {
  background-color: $white;
  background-image: $aqt-filter-item-gradient;
}

.aqt-customizable-filter-tag {
  border: 1px solid $gray;
  color: $aqt-font-color-dark;
}

.aqt-customizable-filter-tag:hover {
  /* background-image is used instead of background-color
  since we needed to override the blue gradient */
  background-image:
    linear-gradient(
      to right,
      $gray-light-active,
      $gray-light-active
    );
}
