// Manually hide Eyedropper component as clicking it causes a crash
[title='Eyedropper'] {
  display: none;
}

.literally .button-style-1 {
  border: 2px solid transparent;
  border-radius: 3px;
}
.literally .button-style-1 {
  cursor: pointer;
  text-decoration: none;
}
.literally .button-style-1.selected:not(.disabled) {
  background-color: #a1d9fe;
}
.literally .button-style-1:hover:not(.disabled) {
  border-color: #a1d9fe;
}
.literally .button-style-1.disabled {
  cursor: default;
  opacity: 0.3;
}
.literally.toolbar-at-top .lc-drawing {
  bottom: 0;
  top: 31px;
}
.literally.toolbar-at-top .lc-options {
  border-bottom: 1px solid #555;
  top: 0;
}
.literally.toolbar-at-bottom .lc-drawing {
  bottom: 31px;
  top: 0;
}
.literally.toolbar-at-bottom .lc-options {
  border-top: 1px solid #555;
  bottom: 0;
}
.literally.toolbar-hidden .lc-drawing {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.literally.toolbar-hidden .lc-options,
.literally.toolbar-hidden .lc-picker {
  display: none;
}
.literally {
  background-color: #ddd;
  min-height: 400px;
  position: relative;
  -ms-touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.literally,
.literally * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.literally > * {
  position: absolute;
}
.literally .lc-picker {
  background-color: #e6e6e6;
  bottom: 0;
  left: 0;
  top: 0;
  width: 61px;
}
.literally .lc-drawing {
  bottom: 0;
  left: 0;
  left: 0;
  right: 0;
}
.literally .lc-drawing > * {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.literally .lc-drawing.with-gui {
  cursor: default;
  left: 61px;
  right: 0;
}
.literally .lc-drawing.with-gui .polygon-toolbar {
  height: 31px;
  top: auto;
}
.literally .lc-drawing.with-gui .polygon-toolbar .polygon-toolbar-button {
  float: left;
}
.literally .lc-drawing.with-gui .text-tool-input:focus {
  outline: none;
}
.literally .lc-picker {
  border-right: 1px solid #555;
  z-index: 1001;
}
.literally .lc-picker .toolbar-button {
  border: 2px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
  margin: 2px;
  padding: 0;
  text-align: center;
  width: 26px;
}
.literally .lc-picker .toolbar-button {
  cursor: pointer;
  text-decoration: none;
}
.literally .lc-picker .toolbar-button.selected:not(.disabled) {
  background-color: #a1d9fe;
}
.literally .lc-picker .toolbar-button:hover:not(.disabled) {
  border-color: #a1d9fe;
}
.literally .lc-picker .toolbar-button.disabled {
  cursor: default;
  opacity: 0.3;
}
.literally .lc-picker .thin-button {
  cursor: pointer;
  float: left;
  position: relative;
}
.literally .lc-picker .fat-button {
  clear: both;
  width: 56px;
}
.literally .lc-picker .lc-pick-tool,
.literally .lc-picker .lc-undo,
.literally .lc-picker .lc-redo,
.literally .lc-picker .lc-zoom-in,
.literally .lc-picker .lc-zoom-out {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.literally .color-well {
  float: left;
  font-size: 10px;
  width: 60px;
}
.literally .color-well.open {
  background-color: #a1d9fe;
}
.literally .color-well-color-container {
  border: 2px solid transparent;
  border: 1px solid #aaa;
  border-radius: 3px;
  height: 28px;
  margin: 1px auto;
  overflow: visible;
  position: relative;
  width: 28px;
}
.literally .color-well-color-container {
  cursor: pointer;
  text-decoration: none;
}
.literally .color-well-color-container.selected:not(.disabled) {
  background-color: #a1d9fe;
}
.literally .color-well-color-container:hover:not(.disabled) {
  border-color: #a1d9fe;
}
.literally .color-well-color-container.disabled {
  cursor: default;
  opacity: 0.3;
}
.literally .color-well-color-container .color-well-checker {
  background-color: black;
  height: 50%;
  position: absolute;
  width: 50%;
}
.literally .color-well-color-container .color-well-checker-top-left {
  border-top-left-radius: 3px;
}
.literally .color-well-color-container .color-well-checker-bottom-right {
  border-bottom-right-radius: 3px;
}
.literally .color-well-color-container .color-well-color {
  border-radius: 3px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.literally .color-picker-popup {
  background-color: white;
  border: 1px solid #555;
  bottom: 31px;
  left: 60px;
  position: absolute;
  z-index: 1;
}
.literally .color-picker-popup .color-row {
  clear: both;
}
.literally .color-picker-popup .color-row .color-cell {
  cursor: pointer;
  float: left;
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.literally .color-picker-popup .color-row .color-cell:hover,
.literally .color-picker-popup .color-row .color-cell.selected {
  border: 1px solid #555;
  line-height: 18px;
}
.literally .color-picker-popup .color-row .color-cell.transparent-cell {
  width: 100%;
}
.literally .horz-toolbar {
  background-color: #e6e6e6;
  height: 31px;
}
.literally .horz-toolbar .label {
  font-size: 12px;
  line-height: 30px;
  margin: 0 0.25em 0 0.25em;
}
.literally .horz-toolbar span {
  float: left;
  font-size: 12px;
  line-height: 30px;
  margin: 0 0.25em 0 0.25em;
}
.literally .horz-toolbar .square-toolbar-button {
  border: 2px solid transparent;
  border: 1px solid #aaa;
  border-radius: 3px;
  float: left;
  height: 28px;
  margin: 1px;
  position: relative;
  width: 28px;
}
.literally .horz-toolbar .square-toolbar-button {
  cursor: pointer;
  text-decoration: none;
}
.literally .horz-toolbar .square-toolbar-button.selected:not(.disabled) {
  background-color: #a1d9fe;
}
.literally .horz-toolbar .square-toolbar-button:hover:not(.disabled) {
  border-color: #a1d9fe;
}
.literally .horz-toolbar .square-toolbar-button.disabled {
  cursor: default;
  opacity: 0.3;
}
.literally .horz-toolbar .square-toolbar-button img {
  max-height: 100%;
  max-width: 100%;
}
.literally .horz-toolbar .square-toolbar-button label {
  bottom: 0;
  float: none;
  left: 0;
  line-height: 26px;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
.literally .polygon-toolbar {
  border-bottom: 1px solid #555;
  border-top: 1px solid #555;
  position: absolute;
  width: 100%;
}
.literally.toolbar-at-bottom .polygon-toolbar {
  top: -100%;
}
.literally.toolbar-at-top .polygon-toolbar {
  top: 100%;
}
.literally .lc-options {
  left: 61px;
  right: 0;
  z-index: 1;
}
.literally .lc-options .lc-font-settings {
  background-color: #f5f5f5;
  height: 30px;
  line-height: 31px;
  padding-left: 4px;
}
.literally .lc-options .lc-font-settings input {
  margin: 0 0.5em 0 0;
}
.literally .lc-options .lc-font-settings input[type='checkbox'] {
  margin: 0 0.5em 0 0.5em;
}
