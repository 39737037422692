.remove-row-button {
  background: transparent;
  border-color: transparent;
  border-style: solid;

  &__icon {
    color: $slate;

    &:hover {
      color: $slate-hover;
    }

    &--disabled {
      color: $gray;
    }
  }
}
