@keyframes ui-toast-open-animation {
  from {
    opacity: 0;
    transform: translateY(-120%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes ui-toast-close-animation {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.ui-toast-manager {
  left: 0;
  margin: 0 auto;
  max-width: 560px;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: #{$zen-popover-z-index + 1000};
}

.ui-toast {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 0;
  transition: all 240ms cubic-bezier(0, 0, 0.2, 1);

  &--open {
    animation:
      ui-toast-open-animation 240ms
      cubic-bezier(0.175, 0.885, 0.32, 1.175) both;
  }

  &--exiting {
    animation: ui-toast-close-animation 120ms cubic-bezier(0.4, 0, 1, 1) both;
  }

  &__block {
    padding: $space-xs-px;
    pointer-events: all;
  }

  &__content {
    box-shadow:
      rgb(67 90 111 / 30%) 0 0 1px,
      rgb(67 90 111 / 47%) 0 8px 10px -4px;
  }
}
