@use "sass:color";

// /*
//  * Alerts
//  * Modified from smartadmin while we work on standardizing UI components
//  */
$state-success-text: #3c763d !default;
$state-success-bg: #dff0d8 !default;
$state-success-border: color.adjust(
  color.adjust($state-success-bg, $hue: -10),
  $lightness: -5%
) !default;
$state-info-text: #31708f !default;
$state-info-bg: #d9edf7 !default;
$state-info-border: color.adjust(
  color.adjust($state-info-bg, $hue: -10),
  $lightness: -7%
) !default;
$state-warning-text: #8a6d3b !default;
$state-warning-bg: #fcf8e3 !default;
$state-warning-border: color.adjust(
  color.adjust($state-warning-bg, $hue: -10),
  $lightness: -5%
) !default;
$state-danger-text: #a94442 !default;
$state-danger-bg: #f2dede !default;
$state-danger-border: color.adjust(
  color.adjust($state-danger-bg, $hue: -10),
  $lightness: -5%
) !default;

.alert {
  border: solid;
  border-width: 0 0 0 5px;
  padding: 10px;
}

.alert-danger {
  background-color: $state-danger-bg;
  border-color: $state-danger-border;
  color: $state-danger-text;
}

.alert-warning {
  background-color: $state-warning-bg;
  border-color: $state-warning-border;
  color: $state-warning-text;
}

.alert-success {
  background-color: $state-success-bg;
  border-color: $state-success-border;
  color: $state-success-text;
}

.alert-info {
  background-color: $state-info-bg;
  border-color: $state-info-border;
  color: $state-info-text;
}
