.ui-table-visualization {
  display: flex;
  flex-direction: column;

  &__table {
    overflow: hidden;
  }

  &__table-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__row {
    align-items: center;
    display: flex;
    flex-direction: row;

    &--odd {
      background-color: #f0f0f0;
    }

    &--clickable {
      &:hover {
        background-color: $white-hover;
        cursor: pointer;
      }
    }

    &--even-clickable {
      &:hover {
        background-color: $white-hover;
        cursor: pointer;
      }
    }

    &--odd-clickable {
      &:hover {
        background-color: $gray-light-hover;
        cursor: pointer;
      }
    }
  }

  &__table-cell {
    flex: 1 1 20px;
    height: 100%;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    // NOTE(sergey) if changed, components/ui/visualizations/Table/index.jsx
    // newHeight to be updated
    padding-top: $space-xs-px;
  }

  &__search-box {
    // Need to override annoying SQT form-control styles.
    font-size: 13px !important;
    font-weight: 500 !important;
    height: 25px !important;
    left: initial !important;
    width: 200px;
  }

  &__no-rows {
    align-items: center;
    color: #bdbdbd;
    display: flex;
    height: 100%;
    justify-content: center;
  }
}

.ui-table-visualization-header {
  align-items: stretch;
  border-bottom: 2px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  overflow: hidden;
  user-select: none;

  &__cell-wrapper {
    align-items: flex-end;
    cursor: pointer;
    display: flex;
    flex: 1 1 20px;
    min-width: 0;
    padding-bottom: $space-xxs-px;
    padding-top: $space-xxs-px;

    &--vertical {
      // TODO(david): Ideally this should scale with the length of the text.
      height: 200px;
    }

    // &:last-of-type {
    //   margin-right: 0;
    // }
  }

  &__cell {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--vertical {
      height: 100%;
      transform: rotate(180deg);
      writing-mode: vertical-rl;
    }

    &--sorted {
      max-width: calc(100% - 13px);
    }

    &--wrapped {
      white-space: normal;
    }
  }
}

.ui-table-visualization-sort-indicator {
  fill: currentcolor;
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
}
