/* Lato - Thin */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 100;
  src:
    local("Lato Light"),
    local("Lato-Light"),
    url("../fonts/lato/Lato-Thin.ttf") format("truetype");
}

/* Lato - Thin italic */
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 100;
  src:
    local("Lato Light Italic"),
    local("Lato-LightItalic"),
    url("../fonts/lato/Lato-Thin.ttf") format("truetype");
}

/* Lato - Light */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  src:
    local("Lato Light"),
    local("Lato-Light"),
    url("../fonts/lato/Lato-Light.ttf") format("truetype");
}

/* Lato - Light italic */
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 300;
  src:
    local("Lato Light Italic"),
    local("Lato-LightItalic"),
    url("../fonts/lato/Lato-LightItalic.ttf") format("truetype");
}

/* Lato - Regular */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src:
    local("Lato Regular"),
    local("Lato-Regular"),
    url("../fonts/lato/Lato-Regular.ttf") format("truetype");
}

/* Lato - Regular italic */
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  src:
    local("Lato Regular Italic"),
    local("Lato-RegularItalic"),
    url("../fonts/lato/Lato-RegularItalic.ttf") format("truetype");
}

/* Lato - Bold */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src:
    local("Lato Bold"),
    local("Lato-Bold"),
    url("../fonts/lato/Lato-Bold.ttf") format("truetype");
}

/* Lato - Bold italic */
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  src:
    local("Lato Bold Italic"),
    local("Lato-BoldItalic"),
    url("../fonts/lato/Lato-BoldItalic.ttf") format("truetype");
}

/* Lato - Black */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  src:
    local("Lato Black"),
    local("Lato-Black"),
    url("../fonts/lato/Lato-Black.ttf") format("truetype");
}

/* Lato - Black italic */
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 900;
  src:
    local("Lato Black Italic"),
    local("Lato-BlackItalic"),
    url("../fonts/lato/Lato-BlackItalic.ttf") format("truetype");
}
