.new-user-button {
  float: right;
}

.relative {
  position: relative;
}

.auth-button-container {
  position: absolute;
  right: 0;
}

.remember-me-container {
  float: left;
}
