.catalog-setup {
  margin: auto;
  max-width: 2112px;
  padding: $space-xl-px $space-l-px $space-l-px $space-l-px;

  &__content {
    padding-top: $space-m-px;
  }
}

.catalog-setup-page-header-actions {
  &__batch-buttons {
    padding-left: $space-s-px;
  }

  &__default-buttons {
    display: flex;
    margin-left: auto;
  }

  &__upload-download-button > .zen-icon {
    color: $blue-primary;
    height: $space-l-px;
    width: $space-l-px;
  }
}

.cs-update-calculation-action {
  &__calculation-popover {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 2px 3px rgb(0 0 0 / 20%);
    min-width: 200px;

    .cs-editable-calculation__customization-block {
      padding: $space-m-px $space-m-px 0 $space-m-px;
    }
  }
}

.cs-batch-publish-action {
  &__title {
    font-size: 14px;
  }

  &__indicator-names {
    ul.b {
      list-style-type: square;
    }
  }
}
