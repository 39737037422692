.insights-fields-selector {
  margin-top: 20px;
  padding-bottom: 10px;

  &__label {
    font-size: $font-l;
    margin-bottom: $spacing-xs;
  }

  &__dropdown {
    // override zen-dropdown formatting to display dropdown at 100% width
    .zen-dropdown,
    .zen-dropdown-button {
      display: block;
    }
  }

  // copied from dq-dropdown
  &__button {
    border: 1px solid $gray;
    border-radius: 5px;
    box-shadow: -1px 2px 2px rgb(0 0 0 / 20%);
    font-size: $font-m;
  }

  &__option {
    font-size: $font-m;
  }
}
